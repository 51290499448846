@media (max-width: 768px) {
  .adaptive-class {
    display: block !important;
    width: 100%;
    margin: auto;

    &__select {
      width: 95% !important;
    }

    .col {
      width: 100% !important;
      flex: unset;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .col-md-4,
    .col-md-5 {
      width: 100% !important;
      flex: unset;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .ant-picker {
      width: 100%;
    }
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .block-add {
    justify-content: center;
  }
  .card-body {
    padding: 0;
  }
}
.detail-photos {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@keyframes myAnimation {
  0% {
    opacity: 1;
    transform: rotateX(90deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
@keyframes logoAnimation {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
.logo-animate {
  animation-name: myAnimation;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}
.mini-logo-animate {
  animation-name: logoAnimation;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}
