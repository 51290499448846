@import '@coreui/coreui/dist/css/coreui.min.css';

a {
  text-decoration: none;
}
.nav-link {
  z-index: 100;
}
/* .nav-link svg {
  z-index: -20;
  margin-right: 20px;
} */

body {
  font-family: '-apple-system', 'BlinkMacSystemFont', 'segoe ui', 'Roboto', 'helvetica neue',
    'Arial', 'noto sans', sans-serif, 'apple color emoji', 'segoe ui emoji', 'segoe ui symbol',
    'noto color emoji';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
}

.card-filter {
  border-radius: 0.428rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.card {
  border-radius: 0.428rem;
}
.avatar-img {
  height: 100%;
}
.ant-input {
  color: black;
  border-radius: 0.357rem !important;
}
.ant-select-selector {
  color: black;

  border-radius: 0.357rem !important;
}
.tab-content .tab-pane .ant-select-selector {
  height: 38px !important;
}

.nav-icon-settlements path {
  stroke: #ffffff60 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 0.9rem;
  align-items: center;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}
.sidebar-nav {
  overflow-x: unset;
  overflow-y: unset;
  display: block !important;
}
.nav-item-active {
  background-color: #3c4b64;
}
.nav-item-active .nav-icon {
  pointer-events: none;
}
.sidebar-nav .nav-link.active {
  background: var(--cui-sidebar-nav-link-active-bg, rgba(255, 255, 255, 0.08)) !important;
}
.sidebarUnfold .nav-link {
  padding: 0.8445rem 0.7rem;
}
.sidebarUnfold .nav-icon {
  flex: 0 0 3rem;
}
.user-info__buttons {
  width: 100%;
  color: #fff;
}

.selectSearch::placeholder {
  font-size: 17.5px;
}
